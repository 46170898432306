import React, { useEffect, useState } from 'react'
import Header from '../Atom/Header'
import SideBar from '../SideBar'
import { Outlet, Route } from 'react-router-dom'
import Login from '../Login'

export const Home = () => { 
  const[item,setItem] = useState()
  useEffect(()=>{
    const loginInfo = JSON.parse(localStorage.getItem('loginUser'));
    setItem(loginInfo)
  },[])

  
 
  if(item===null){
    return <Login />
  }
  else{
     return(
      <div className=''>
        <div>
          <div className='bg-lightblue w-full ' >
            <Header />
                  
          </div>      
          <div className="outlet-flex flex">
              <div className='w-[14%] h-[100vh] bg-lightblue top-[0px] sticky overflow-y-auto'>
              <SideBar />   
              </div>
                <div className=' w-[86%]'>
                <Outlet />
                
                </div>
              
          </div>
        </div>
        
    </div>

     )
  }
}
