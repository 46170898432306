import React, { Children, useState } from "react";
import { toastify } from "../Atom/Toastify";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import Select from "react-select";
const options = [
  { value: "6 am", label: "6 am" },
  { value: "7 am", label: "7 am" },
  { value: "8 am", label: "8 am" },
  { value: "9 am", label: "9 am" },
  { value: "10 am", label: "10 am" },
  { value: "11 am", label: "11 am" },
  { value: "12 pm", label: "12 pm" },
  { value: "1 pm", label: "1 pm" },
  { value: "2 pm", label: "2 pm" },
  { value: "3 pm", label: "3 pm" },
  { value: "4 pm", label: "4 pm" },
  { value: "5 pm", label: "5 pm" },
  { value: "6 pm", label: "6 pm" },
  { value: "7 pm", label: "7 pm" },
  { value: "8 pm", label: "8 pm" },
  { value: "9 pm", label: "9 pm" },
  { value: "10 pm", label: "10 pm" },
];

function AddShifts({ setModal, setLoader, editData }) {
  const [errors, setErrors] = useState({});
  const [disbleDate, setDisbleDate] = useState();

  const isEdit = Object.keys(editData).length > 0;

  const [user, setUser] = useState({
    name: editData.name ? editData.name : "",
    startTime: editData.startTime ? options.find(opt => opt.label === editData.startTime)?.value : "",
    endTime: editData.endTime ? options.find(opt => opt.label === editData.endTime)?.value : "",
    startDate: editData.startDate
      ? moment(editData.startDate).format("YYYY-MM-DD")
      : "",
    endDate: editData.endDate
      ? moment(editData.endDate).format("YYYY-MM-DD")
      : "",
  });
  const validate = () => {
    const errors = {};
    if (!user.name) {
      errors.name = "Name is required";
    }
    return errors;
  };
  const handelInput = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
    const validationErrors = validate();

    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
    }
    if (user.startDate >= user.endDate) {
      setDisbleDate(true);
    }
  };
  const handelSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
      AddShiftData();
    } else {
      setErrors(validationErrors);
    }
  };

  const handleSelectChange = (event, fieldName) => {
    const selectedValue = event.target.value;
  
    setUser((prevState) => ({
      ...prevState,
      [fieldName]: selectedValue, // Store only the selected value
    }));
  };
  const AddShiftData = async () => {
    const url = isEdit
      ? `${process.env.REACT_APP_BASE_URL}shifts/${editData.id}`
      : `${process.env.REACT_APP_BASE_URL}shifts/AddShift`;
    try {
      const response = isEdit
        ? await axios.put(url, {
            name: user.name,
            startDate: user.startDate,
            endDate: user.endDate,
            startTime: user.startTime,
            endTime:user.endTime,
          })
        : await axios.post(url, {
            name: user.name,
            startDate: user.startDate,
            endDate: user.endDate,
            startTime: user.startTime,
            endTime: user.endTime,
          });

      if (response.status == 200) {
        if (isEdit) {
          setUser("");
        }
        toast.success(`Shift ${isEdit ? "Updated" : "Added"} Successfully`);
        setLoader(true);
        setModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div>
      <div>
        <div>
          <h3 class="text-xl mb-4 font-bold text-left pb-2 border-b-2 border-gray">
            {editData ? "Edit Shift" : "Add New Shift"}
          </h3>
          <div>
            <label
              for="first_name"
              class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white"
            >
              Name
            </label>

            <input
              name="name"
              value={user.name}
              onChange={handelInput}
              type="text"
              id="first_name"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray "
              placeholder="Enter Name"
              required
            />
            {errors.name && <p className="text-left text-red">{errors.name}</p>}
          </div>
          <label
            for="first_name"
            class="block mb-2 mt-2 text-left text-sm font-medium text-gray-900 dark:text-white"
          >
            Start time
          </label>

          <select
      id="startTimeSelect"
      className="w-full h-[40px] border px-1 border-gray"
      value={user.startTime}
      onChange={(e) => handleSelectChange(e, "startTime")}
    >
      <option value="">Select Start Time</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
          {/* <Select
            name="shifttime"
            value={user.startTime}
            options={options}
            onChange={(selected) => handleSelectChange(selected, "starttime")}
            className="basic-multi-select rounded-sm text-left text-sm"
            classNamePrefix="select skills"
            placeholder="Select Shift"
          /> */}
          <label
            for="first_name"
            class="block mb-2 mt-2 text-left text-sm font-medium text-gray-900 dark:text-white"
          >
            End time
          </label>
          <select
      id="endTimeSelect"
      className="w-full h-[40px] border px-1 border-gray"
      value={user.endTime}
      onChange={(e) => handleSelectChange(e, "endTime")}
    >
      <option value="">Select End Time</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
          {/* <Select
            name="shifttime"
            value={user.endTime}
            options={options}
            onChange={(selected) => handleSelectChange(selected, "endtime")}
            //onChange={handleShiftChange}
            className="basic-multi-select rounded-sm text-left text-sm"
            classNamePrefix="select skills"
            placeholder="Select Shift"
            //value={shiftOptions.find(option => option.label === user?.shiftId)}
          /> */}

          <label
            for="first_name"
            class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white pt-2"
          >
            Start Date
          </label>
          <input
            type="date"
            name="startDate"
            value={user.startDate}
            onChange={handelInput}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray "
          />
        </div>
        <div>
          <label
            for="first_name"
            class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white pt-2"
          >
            End Date
          </label>
          <input
            type="date"
            disabled={!disbleDate}
            min={user.startDate}
            value={user.endDate}
            name="endDate"
            onChange={handelInput}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray "
          />
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse mb-6">
        <span className="mt-8 flex w-full justify-center gap-4 rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <button
            type="button"
            onClick={(e) => handelSubmit(e)}
            className="inline-flex justify-center w-1/3 rounded-md border border-gray-300 px-4 py-2   hover:bg-lightblue hover:text-black text-white bg-blue leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            {editData ? "Update" : "Submit"}
          </button>
          <button
            type="button"
            onClick={() => setModal(false)}
            className="inline-flex justify-center w-1/3 rounded-md border border-gray-300 px-4 py-2  text-white bg-lightred leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            Close
          </button>
        </span>
      </div>
    </div>
  );
}

export default AddShifts;
