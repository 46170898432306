import React, { Children, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
function AddSkill({setModal,setLoader,editData}) {
  const isEdit = Object.keys(editData).length>0 ;

  const [errors, setErrors] = useState({});
  const [user, setUser] = useState({
    name: isEdit ? editData.name : '',
  });
  const validate = () => {
    const errors = {};
    if (!user.name) {
      errors.name = "Skill Name is required";
    }
    return errors;
  };
  const handelInput = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
    const validationErrors = validate();

    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
    }
  };
  
  const handelSubmit = (e)=>{
    e.preventDefault();
    const validationErrors = validate();
    if(Object.keys(validationErrors).length === 0){
        setModal(false)
        setErrors({});
        AddSkillsData()

    }
    else{
        setErrors(validationErrors);  
    }
  }


const AddSkillsData = async () => {
  const url=isEdit ? `${process.env.REACT_APP_BASE_URL}skills/${editData.id}` : `${process.env.REACT_APP_BASE_URL}skills/AddSkill`
  try {
    const response = isEdit ? await axios.put(
      url ,{
           name:user.name
       }
     ) : await axios.post(
     url ,{
          name:user.name
      }
    );
   
    if(response.status == 200){
      if(isEdit){
       
        setUser('')
        
      }
      toast.success(`Skill ${isEdit ? 'Updated':'Added'} Successfully`)
      setLoader(true)
    }
  } catch (error) {
    console.log(error);
  }
};

  return (
    <div>
      <div>
        <h3 className="text-xl mb-4 font-bold text-left pb-2 border-b-2 border-gray">Add New skill</h3>
        <label
          for="first_name"
          class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white"
        >
          Skill Name
        </label>

        <input
          name="name"
          value={user?.name}
          onChange={handelInput}
          type="text"
          id="first_name"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray "
          placeholder="Enter Skill"
          required
        />
        {errors.name && <p className="text-left text-red">{errors.name}</p>}
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse mb-6">
          <span className="mt-8 flex w-full justify-center gap-4 rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button
              type="button"
              onClick={(e) => handelSubmit(e)}
              className="inline-flex justify-center w-1/3 rounded-md border border-gray-300 px-4 py-2   hover:bg-lightblue hover:text-black text-white bg-blue leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
             {editData? 'Update':'Submit'} 
            </button>
            <button
              type="button"
              onClick={() => setModal(false)}
              className="inline-flex justify-center w-1/3 rounded-md border border-gray-300 px-4 py-2  text-white bg-lightred leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              Close
            </button>
          </span>
        </div>
      </div>
    </div>
  );
}

export default AddSkill;
