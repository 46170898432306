import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";
function ProjectDetails() {
  const location = useLocation();
  const item = location.state?.item;
  const skillName = item.skills.map((item) => item.name);
  const [resource, setResource] = useState();

  const fetchProject = async (item) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}projection/${item.id}`
      );

      if (response.status == 200) {
        setResource(response.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchProject(item);
  }, []);


  const navigate = useNavigate();

  const handleclickBack = () => {
    navigate('/project');
  }

  return (
    <div>
      <div className="px-8 text-left mb-4"></div>
      <h2 className=" mb-8 font-bold text-xl text-left flex items-center gap-2 px-8"><span className=" gap-1 text-sm flex items-center" onClick={handleclickBack}><IoMdArrowRoundBack className="text-lg" />Back</span>Project Details</h2>
      <div className="px-8 m-auto">
        <div class="relative overflow-x-auto border border-gray">
          <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className="border-b border-gray bg-blue text-white">
                <th scope="col" class="px-6 py-3">
                  Project Name
                </th>
                <th scope="col" class="px-6 py-3">
                  Company Name
                </th>
                <th scope="col" class="px-6 py-3">
                  Skills
                </th>
                <th scope="col" class="px-6 py-3">
                  startDate
                </th>
                <th scope="col" class="px-6 py-3">
                  EndDate
                </th>
                <th scope="col" class="px-6 py-3">
                  Time Duration
                </th>
                <th scope="col" class="px-6 py-3">
                  Resource
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-white dark:bg-gray-800 dark:border-gray-700">
                <td class="px-6 py-4  text-gray-900 whitespace-nowrap dark:text-white">
                  {item.name}
                </td>
                <td class="px-6 py-4  text-gray-900 whitespace-nowrap dark:text-white">
                  {item.companyName}
                </td>
                <td class="px-6 py-4">
                  <ul>
                    {skillName.map((item) => (
                      <li>{item}</li> // Assuming each item has a unique 'id'
                    ))}
                  </ul>
                </td>
                <td class="px-6 py-4">
                  {moment(item.startDate).format("YYYY-MM-DD")}
                </td>
                <td class="px-6 py-4">
                  {moment(item.endDate).format("YYYY-MM-DD")}
                </td>
                <td class="px-6 py-4">{item.timeDuration} hrs</td>
                <td class="px-6 py-4">{item.resources}</td>
              </tr>

            </tbody>
          </table>
        </div>
        {resource && !resource?.assignedProjections && <h2 className="mt-8 mb-2 font-bold text-xl text-left">No Available Resource</h2>}
        {resource?.assignedProjections && <> <h2 className="mt-8 mb-2 font-bold text-xl text-left">Available Resource</h2>
          <table className="border border-gray mt-4">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className="border-b border-gray bg-blue text-white">
                <th scope="col" class="px-6 py-3">
                  Resource Id
                </th>
                <th scope="col" class="px-6 py-3">
                  Resource Name
                </th>
                <th scope="col" class="px-6 py-3">
                  skills
                </th>
                <th scope="col" class="px-6 py-3">
                  shifts
                </th>
                <th scope="col" class="px-6 py-3">
                  startDate
                </th>
                <th scope="col" class="px-6 py-3">
                  EndDate
                </th>

              </tr>
            </thead>
            <tbody>
              {resource?.assignedProjections?.map((item) => {
                return (
                  <tr className="border border-gray">
                    <td className="px-6 py-4">

                      R00{(item.resourceId).toString().padStart(2, '0')}
                    </td>
                    <td className="px-6 py-4">

                      <span className="text-sm">{item.resourceName}</span>
                    </td>

                    <td className="px-6 py-4">

                      <span>
                        {item.skills.map((skill) => (
                          <span className="text-sm" key={skill.id}>{skill.name}</span>
                        ))}
                      </span>
                    </td>
                    <td className="px-6 py-4">

                      <span className="text-sm">{item.shiftName}</span>
                    </td>
                    <td className="px-6 py-4">

                      <span className="text-sm"> {moment(item?.projectionStartDate).format("YYYY-MM-DD")}</span>
                    </td>
                    <td className="px-6 py-4">

                      <span className="text-sm">{moment(item?.projectionEndDate).format("YYYY-MM-DD")}</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
        }

      </div>
    </div>
  );
}

export default ProjectDetails;
