import React, { useState, useEffect } from 'react';
import axios from 'axios';
const API_BASE_URL= "https://resourceallocation-api.tglserver.net/api"

export const getSkillData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/${'skills'}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  export const getShiftData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/${'shifts'}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  export const getManagerData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/${'managers'}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  
  export const getProjectData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/${'projects'}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };


    
  export const getLocationData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/${'WorkLocation'}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };