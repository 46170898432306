
import React, { useEffect, useState } from 'react'
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import Modal from '../Atom/Modal';
import { ToastContainer, toast } from 'react-toastify';
import AddShifts from './AddShifts';
import ConfirmationModal from '../Atom/ConfirmationModal';
import axios from "axios";
import Spinners from '../Atom/Spinners';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Pagination from '../Atom/Paginations';
function Shifts() {
  const [modal, setModal] = useState(false)
  const [deleteUser, setDeleteUser] = useState(false);
  const [EditUser, setEditUser] = useState(false);
  const [shiftData, setShiftData] = useState([])
  const [loader, setLoader] = useState(true)
  const [editData, setEditData] = useState({})
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(() => {
    const pageParam = searchParams.get('page');
    return pageParam ? Number(pageParam) : 1;
  });
  const [count, setCount] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [path, setPath] = useState('');

  const navigate = useNavigate();

  const HandleModal = () => {
    setModal(true)
    setDeleteUser(false)
    setEditData('')

  }
  const HandleClickDelete = (id) => {
    setDeleteUser(true)
    setEditData(id)

  }
  const handleClickEdit = (item) => {
    setEditUser(true)
    setDeleteUser(false)
    setModal(true)
    setEditData(item)
  }

  const fetchShift = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}shifts?pageIndex=${page}&pageSize=${pageSize}`
      );
      if (response.status == 200) {
        setShiftData(response?.data?.data);
        setLoader(false)
        setCount(response?.data?.pagination?.totalCount);
      }

    } catch (error) {
      console.log(error);
    }
  };


  const DeleteShift = async (id) => {

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}shifts/${id}`);
      if (response.status == 200) {
        toast.success('shifts Deleted Successfully');
        setDeleteUser(false)
        setModal(false)
        setLoader(true)

      }
    } catch (error) {
      console.log(error);
      toast.error('Allready Deleted')
    }
  };
  const ConfirmDelete = () => {
    DeleteShift(editData)
  }



  useEffect(() => {
    if (loader || page) {
      fetchShift()
    }
  }, [loader, page])

  const handleClickPginate = (selectedPage) => {// Pass query parametersnavigate('/path?param1=value1&param2=value2');
    const newPath = `${window.location.pathname}?page=${selectedPage}`
    navigate(newPath);
    setPath(newPath);
    setLoader(true)

  }
  return (
    <div>
      {loader && <div className="flex justify-center items-center h-[100vh]">
        <Spinners />
      </div>}
      <div className='flex justify-between items-center p-4'>
        <h2 className='font-bold text-xl'>Shifts</h2>
        <button className='bg-blue p-3 text-white font-semibold  rounded-lg hover:bg-lightblue hover:text-black' onClick={() => HandleModal()}>Add Shift</button>
      </div>
      <div className='p-4'>
        <div class="relative overflow-x-auto">
          <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-gray border">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className='bg-lightblue'>
                <th scope="col" class="px-6 py-3 text-sm">
                  S.No
                </th>
                {/* <th scope="col" class="px-6 py-3 text-sm">
                  ID
                </th> */}
                <th scope="col" class="px-6 py-3 text-sm">
                  Name
                </th>
                <th scope="col" class="px-6 py-3 text-sm">
                  Start Date
                </th>
                <th scope="col" class="px-6 py-3 text-sm">
                  End Date
                </th>
                <th scope="col" class="px-6 py-3 text-sm">
                  Time(9-hour shift)
                </th>
                <th scope="col" class="px-6 py-3 text-sm">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>

              {shiftData?.map((item, index) => {
                const startDate = new Date(item.startDate).toISOString().slice(0, 10);
                const endDate = new Date(item.endDate).toISOString().slice(0, 10);
                return (<tr class="bg-white  border-gray border border-b  boder-gray">
                  <th scope="row" class="text-xs px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {/* {index+1} */}
                    S00{(item.id).toString().padStart(2, '0')}
                  </th>
                  {/* <th scope="row" class="text-xs px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {item.id}
                  </th> */}
                  <td class="px-6 py-4 text-xs">
                    {item.name}
                  </td>
                  <td class="px-6 py-4">
                    <div className='flex gap-3 items-center'>
                      <label>{startDate}</label>

                    </div>
                  </td>
                  <td class="px-6 py-4">
                    <label>{endDate}</label>
                  </td>
                  <td class="px-6 py-4">
                    <label>{item.startTime} - {item.endTime}</label>
                  </td>
                  <td class="px-6 py-4">
                    <div className='flex gap-3 items-center'>
                      <div className="relative group">
                        <FaEdit onClick={() => handleClickEdit(item)} className="text-2xl text-blue cursor-pointer" />
                        <span className="absolute hidden group-hover:block bg-black text-white text-xs rounded-md px-2 py-1 -top-8 left-1/2 transform -translate-x-1/2">
                          Edit
                        </span>
                      </div>
                      <div className="relative group">
                        <MdDeleteForever onClick={() => HandleClickDelete(item.id)} title='Delete' className='text-2xl text-blue cursor-pointer' />
                        <span className="absolute hidden group-hover:block bg-black text-white text-xs rounded-md px-2 py-1 -top-8 left-1/2 transform -translate-x-1/2">
                          Delete
                        </span>
                      </div>

                    </div>
                  </td>

                </tr>)
              })}
            </tbody>
          </table>
        </div>
      </div>
      {modal && <Modal setModal={setModal}> <AddShifts setLoader={setLoader} editData={editData} setModal={setModal} deleteUser={deleteUser} /> </Modal>}
      {deleteUser && <ConfirmationModal ConfirmDelete={ConfirmDelete} CloseConfimationsModal={setDeleteUser} />}
      <ToastContainer />
      {count > 10 && <Pagination handleClickPginate={handleClickPginate} page={page} totalItems={count} setPage={setPage} itemsPerPage={pageSize} />}
    </div>
  )
}

export default Shifts