import React from 'react'
import ClipLoader from "react-spinners/ClipLoader";
function Spinners() {
     
  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    width:'60px',
    height:'60px',
    border: '4px solid '
  };
  return (
    <div>
        <ClipLoader
      height="80"
      width="200"
      radius="9"
      color="#00246b"
      ariaLabel="loading"
      wrapperStyle
      wrapperClass
      cssOverride={override}
    />
    </div>
  )
}

export default Spinners