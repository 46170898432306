import React from 'react'
import { useLocation } from 'react-router-dom';

function SideBar() {
  const location = useLocation();
  const { pathname } = location;
  return (
    <div className='py-2 '>     
      <div>
          <ul className='text-left'>
          <li  className={`' p-3 pl-0  text-lg font-bold border-b  border-b-blue ' ${pathname.includes('/resource') && 'bg-blue text-white'}`}><a href='/resource' className='text-xl py-3 pl-6'>Resource</a></li>
          <li  className={`' p-3 pl-0 text-lg font-bold border-b border-b-blue' ${pathname.includes('/project') && 'bg-blue text-white'}`}><a href='/project' className='text-xl py-3 pl-6'>Project</a></li>           
            <li  className={`' p-3 pl-0 text-lg font-bold border-b border-b-blue' ${pathname.includes('/skills') && 'bg-blue text-white'}`}><a href='/skills' className='text-xl py-3 pl-6'>Skills</a></li>
            <li  className={`' p-3 pl-0 text-lg font-bold border-b border-b-blue' ${pathname.includes('/shifts') && 'bg-blue text-white'}`}><a href='/shifts' className='text-xl py-3 pl-6'>Shift</a></li>
            <li  className={`' p-3 pl-0 text-lg font-bold border-b border-b-blue' ${pathname.includes('/manager') && 'bg-blue text-white'}`}><a href='/manager' className='text-xl py-3 pl-6'>Manager</a></li>
            <li  className={`' p-3 pl-0 text-lg font-bold border-b border-b-blue' ${pathname.includes('/location') && 'bg-blue text-white'}`}><a href='/location' className='text-xl py-3 pl-6'>Location</a></li>
          
          </ul>
      </div>  
    </div>
  )
}

export default SideBar