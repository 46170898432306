import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toastify } from "../Atom/Toastify";
import axios from "axios";
import { toast } from "react-toastify";
import Skills from "../Skills";
import {
  getSkillData,
  getShiftData,
  getManagerData,
  getProjectData,
  getLocationData,
} from "../../utils/api";
const options = [
  { value: "1", label: "Monday" },
  { value: "2", label: "Tuesday" },
  { value: "3", label: "Wednesday" },
  { value: "4", label: "Thursday" },
  { value: "5", label: "Friday" },
  { value: "6", label: "Saturday" },
  { value: "7", label: "Sunday" },
];

function AddResource({ editData, setModal, setLoader }) {
  const isEdit = Object.keys(editData).length > 0;
  const [errors, setErrors] = useState({});
  const [disbleDate, setDisbleDate] = useState();
  const [Userskill, setUserSkill] = useState([]);
  const [shift, setShift] = useState([]);
  const [manager, setManager] = useState([]);
  const [projects, setProject] = useState([]);
  const [location, setLocation] = useState([]);
  const [isTrue,setIsTrue] = useState(false)
  const [user, setUser] = useState({
    name: isEdit ? editData.name : "",
    skills: isEdit ? editData?.skills : "",
    //selected: isEdit ? editData.skills.map(skill => ({ value: skill.id, label: skill.name })) : [],
    shiftId: isEdit ? editData?.shiftName : "",
      //shiftName: isEdit ? editData?.shiftName : '',
    phoneNumber: isEdit ? editData?.phoneNumber : "",
    availabilityDays: isEdit ? editData?.availabilityDays : "",
    managerId: isEdit ? editData?.managerName : "",
    locationId: isEdit ? editData?.locationName : "",
    projectId: isEdit ? editData?.projectName : "",
  });
  const validate = () => {
    const errors = {};
    if (!user.name && !user.phoneNumber) {
      errors.name = "Name is required";
      errors.phoneNumber = "Phone Number is required";
    }
    return errors;
  };

  const handleSelectChange = (selectedOption, name) => {
   
    
    setIsTrue(true)
    setUser(prevState => ({
      ...prevState,
      [name]: Array.isArray(selectedOption)
        ? selectedOption.map(option => (editData ? option.label : option.value))
        : editData
        ? selectedOption.label
        : selectedOption.value,
    }));
  };

  const handleskillChange = (selectedOption, name) => {
   
    
    setIsTrue(true)
    setUser(prevState => ({
      ...prevState,
      [name]: Array.isArray(selectedOption)
        ? selectedOption.map(option => (editData ? option : option))
        : editData
        ? selectedOption
        : selectedOption,
    }));
  };


  
  const handelInput = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
    const validationErrors = validate();

    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
    }
    if (user.startDate >= user.endDate) {
      setDisbleDate(true);
    }
  };
  const handelSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      AddResource();
      setErrors({});
    } else {
      setErrors(validationErrors);
    }
  };


  useEffect(() => {
    async function fetchItems() {
      try {
        const data = await getSkillData(); // Fetch data from /api/items
        const shiftData = await getShiftData();
        const mangerData = await getManagerData();
        const project = await getProjectData();
        const location = await getLocationData();
        setUserSkill(data?.data);
        setShift(shiftData?.data);
        setManager(mangerData?.data);
        setProject(project?.data);
        setLocation(location?.data);
      } catch (error) {
        console.error("Failed to fetch items:", error);
      }
    }
    fetchItems();
  }, []);
  

  const selecteskill = Userskill?.map((item) => {
    return {
      label: item.name, // Mapping name to label
      value: item.id, // Mapping id to value
    };
  });

  useEffect(()=>{
    if(isEdit){
      const selectedEditSkill=editData?.skills.map((item)=>{
        return{
          label: item.name,
          value:item.id
        }
        
      })
      const selectedEditAviliblity=editData?.availabilityDays.map((item)=>{
        return{
          label: item.name,
          value:item.id
        }
        
      })
      setUser({...user,skills:selectedEditSkill,availabilityDays:selectedEditAviliblity})
    }
   
  },[isEdit])


  const AddResource = async () => {
    const url = isEdit
      ? `${process.env.REACT_APP_BASE_URL}resources/${editData.id}`
      : `${process.env.REACT_APP_BASE_URL}resources/AddResource`;
    try {
      const response = isEdit
        ? await axios.put(url, {
          name: user.name,
        Skills:user.skills.map(skill => skill.value),
         shiftId: shift.filter(option => option.name === user?.shiftId)[0]?.id,
        //shiftId: user.shiftName ? user.shiftName.value : null,
        locationId:locationOption.filter(option => option.label === user?.locationId)[0]?.value,
        managerId: managerOption.filter(option => option.label === user?.managerId)[0]?.value,
        projectId:projectOption.filter(option => option.label === user?.projectId)[0]?.value,
        phoneNumber:user.phoneNumber,
        availabilityDays: user.availabilityDays.map(option => option.value)
          })
        : await axios.post(url, {
            name: user.name,
            Skills:user.skills.map(skill => skill.value),
            shiftId: user.shiftId,
            locationId:user.locationId,
            managerId: user.managerId,
            projectId:user.projectId,
            phoneNumber:user.phoneNumber,
            availabilityDays: user.availabilityDays.map(option => option.value)
          });

      if (response.status == 200) {
        if (isEdit) {
          setUser("");
        }
        toast.success(`Resource ${isEdit ? "Updated" : "Added"} Successfully`);
        setLoader(true);
        setModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [selectedOptions, setSelectedOptions] = useState({
    shiftId: null,
    managerId: null,
    locationId: null,
    projectId: null,
  });


  

  const shiftOptions = shift?.map((item) => {
    return {
      label: item.name, // Mapping name to label
      value: item.id, // Mapping id to value
    };
  });

  const managerOption = manager.map((item) => {
    return {
      label: item.name, // Mapping name to label
      value: item.id, // Mapping id to value
    };
  });
  const projectOption = projects.map((item) => {
    return {
      label: item.name, // Mapping name to label
      value: item.id, // Mapping id to value
    };
  });

  const locationOption = location.map((item) => {
    return {
      label: item.name, // Mapping name to label
      value: item.id, // Mapping id to value
    };
  });

  const editskill = editData?.skills?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  
  const editavibilty = editData?.availabilityDays?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const handleShiftChange = (selectedOption) => {
    setUser(prevUser => ({
      ...prevUser,
      shiftName: selectedOption // Set the selected shift
    }));
  };

  const editProject = projects?.map((item) => ({
    label: item.name,
    value: item.id,
  }));
    

  

  return (
    <div> 
      <h3 class="text-xl mb-4 font-bold text-left pb-2 border-b-2 border-gray">{editData ? 'Edit Resource':'Add New Resource'}</h3>
      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        <div className="flex items-center justify-between gap-5">
          <div className="w-1/2">
          <label
            for="first_name"
            class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white"
          >
            Name
          </label>

          <input
            name="name"
            value={user.name}
            onChange={handelInput}
            type="text"
            id="first_name"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray "
            placeholder="Enter Name"
            required
          />
          {errors.name && <p className="text-left text-red">{errors.name}</p>}
          <label
            for="first_name"
            class="block mb-2 mt-2 text-left text-sm font-medium text-gray-900 dark:text-white"
          >
            Shift
          </label>
          <Select
            name="shiftId"
            options={shiftOptions}
            onChange={(selected) => handleSelectChange(selected, "shiftId")}
            //onChange={handleShiftChange}
            className="basic-multi-select rounded-sm text-left text-sm"
            classNamePrefix="select skills"
            placeholder="Select Shift"
            value={shiftOptions.find(option => option.label === user?.shiftId)}
          />
          <label
            for="first_name"
            class="block mb-2 mt-2 text-left text-sm font-medium text-gray-900 dark:text-white"
          >
            Skill
          </label>
          <Select
            isMulti
            name="skills"
            options={selecteskill}
            onChange={(selectedOptions) =>
              handleskillChange(selectedOptions, "skills")
            }
            className="basic-multi-select rounded-sm text-left text-sm"
            classNamePrefix="select skills"
            defaultValue={editData?editskill:null}
          />

          <label
            for="first_name"
            class="block mb-2 mt-2 text-left text-sm font-medium text-gray-900 dark:text-white"
          >
            Manager
          </label>

          <Select
            name="managerId"
            options={managerOption}
            onChange={(selected) => handleSelectChange(selected, "managerId")}
            className="basic-multi-select rounded-sm text-left text-sm"
            classNamePrefix="select skills"
            placeholder="Select Manager"
            value={managerOption.find(option => option.label === user?.managerId)}
          />
          </div>
         <div className="w-1/2">
         <label
            for="first_name"
            class="block mb-2 mt-2 text-left text-sm font-medium text-gray-900 dark:text-white"
          >
            Project
          </label>

          <Select
            name="projectId"
            options={projectOption}
            onChange={(selected) => handleSelectChange(selected, "projectId")}
            className="basic-multi-select rounded-sm text-left text-sm"
            classNamePrefix="select skills"
            placeholder="Select Project"
            value={projectOption.find(option => option.label === user?.projectId)}
          />
          {/* {errors.name && <p className="text-left text-red">{errors.name}</p>} */}
          <label
            for="first_name"
            class="block mb-2 mt-2 text-left text-sm font-medium text-gray-900 dark:text-white"
          >
            Loction
          </label>

          <Select
            name="locationId"
            options={locationOption}
            onChange={(selected) => handleSelectChange(selected, "locationId")}
            className="basic-multi-select rounded-sm text-left text-sm"
            classNamePrefix="select skills"
            placeholder="Select Loctaion"
            value={locationOption.find(option => option.label === user?.locationId)}
          />
          {/* {errors.name && <p className="text-left text-red">{errors.name}</p>} */}
          <label
            for="first_name"
            class="block mb-2 mt-2 text-left text-sm font-medium text-gray-900 dark:text-white"
          >
            Phone Number
          </label>

          <input
            name="phoneNumber"
            value={user.phoneNumber}
            onChange={handelInput}
            type="number"
            id="first_name"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray "
            placeholder="Enter Phone Number"
            required
          />
          {errors.phoneNumber && <p className="text-left text-red">{errors.phoneNumber}</p>}
          <label
            for="first_name"
            class="block mb-2  mt-2 text-left text-sm font-medium text-gray-900 dark:text-white"
          >
            Availability Days
          </label>
          <Select
            isMulti
            name="availabilityDays"
            options={options}
            onChange={(selectedOptions) =>
              handleskillChange(selectedOptions, "availabilityDays")
            }
            className="basic-multi-select rounded-sm text-left text-sm"
            classNamePrefix="select skills"
            defaultValue={editavibilty}
          />
         </div>
          {/* {errors.name && <p className="text-left text-red">{errors.name}</p>} */}
                  
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse mb-6">
          <span className="mt-8 flex w-full justify-center gap-4 rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button
              type="button"
              onClick={(e) => handelSubmit(e)}
              className="inline-flex justify-center w-1/3 rounded-md border border-gray-300 px-4 py-2   hover:bg-lightblue hover:text-black text-white bg-blue leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
             {editData? 'Update':'Submit'} 
            </button>
            <button
              type="button"
              onClick={() => setModal(false)}
              className="inline-flex justify-center w-1/3 rounded-md border border-gray-300 px-4 py-2  text-white bg-lightred leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              Close
            </button>
          </span>
        </div>
      </div>
    </div>
  );
}

export default AddResource;
