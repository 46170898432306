import React from "react";
import Modal from "../Modal";
function ConfirmationModal({CloseConfimationsModal,ConfirmDelete}) {

  return (
    <Modal setModal={CloseConfimationsModal}>
      <div>
        <div>
          <div class="px-6 py-2 pt-0 text-center">
            <svg
              class="w-20 h-20 text-lightred mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <h3 class="text-xl font-normal text-gray-500  mb-2 mt-2">
              Are you sure you want to Delete
            </h3>
            <a
              href="#"
              onClick={()=>ConfirmDelete()}
              class="text-white bg-blue hover:bg-lightblue hover:text-black border border-gray-200 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-base inline-flex items-center px-3 py-2.5 text-center mr-2"
            >
              Yes, I'm sure
            </a>
            <a
              href="#"
              onClick={() => CloseConfimationsModal(false)}
              class="text-gray-900 text-white bg-blue hover:bg-lightblue hover:text-black focus:ring-4 focus:ring-cyan-200 border border-gray-200 font-medium inline-flex items-center rounded-lg text-base px-3 py-2.5 text-center"
              data-modal-toggle="delete-user-modal"
             >
              No, cancel
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
