import React, { Children, useState } from "react";
import { IoClose } from "react-icons/io5";
import ConfirmationModal from "../ConfirmationModal";

function Modal({ setModal, deleteUser, addSkills, children }) {
  return (
    <div>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="overlay-div flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>

          <div className="inline-block max-w-[600px] align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-right shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full  w-full sm:p-6">
            <div className="text-right flex justify-end">
              <IoClose
                onClick={() => setModal(false)}
                className="text-lightred text-xl "
              />
            </div>

            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
