import logo from './logo.svg';
import './App.css';
import AppRouter from './Components/Router/AppRouter';
import React, {useEffect} from 'react';

function App() { 
  return (
    <div className="App">
       <AppRouter />
    </div>
  );
}

export default App;
