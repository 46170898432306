import React, { Children, useState,useEffect } from "react";
import { toastify } from "../Atom/Toastify";
import { toast } from "react-toastify";
import Select from "react-select";
import axios from "axios";
import { getSkillData } from "../../utils/api";
import moment from 'moment';
function AddProject({ setModal,editData,setLoader }) {
  const [selected, setSelected] = useState([]);
  const [errors, setErrors] = useState({});
  const [disbleDate, setDisbleDate] = useState();
  const isEdit = Object.keys(editData).length>0 ;
  const [userSkill,setUserSkill] = useState([])
  const [editseleted,setEditSeleted] = useState([])
  const [trueValue,setTrueValue] = useState(false)
  const [user, setUser] = useState({
    name: editData ? editData.name:"",
    skills:editData? editData.skills:[],
    startDate: editData ? moment(editData.startDate).format('YYYY-MM-DD') : '',
    endDate:editData ? moment(editData.endDate).format('YYYY-MM-DD') : '',
    companyName:editData ? editData.companyName:'',
    resources:editData ? editData.resources:"",
    timeDuration:editData ? editData.timeDuration:""
  });
  

  const validate = () => {
    const errors = {};
    if (!user.name && !user.skill) {
      errors.name = "Name is required";
      errors.skill = "Skill is required";
      errors.resources = "Resource is required"
    }
    return errors;
  };

  const handleSelectChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setUser({ ...user, skills: selectedValues });
    setTrueValue(true)
  };
  const handelInput = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
    const validationErrors = validate();

    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
    }
    if (user.startDate >= user.endDate) {
      setDisbleDate(true);
    }
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      setErrors({});
      AddProjectData()
     
    } else {
      setErrors(validationErrors);
    }
  };

  const AddProjectData = async () => {
    const url=isEdit ? `${process.env.REACT_APP_BASE_URL}projects/${editData.id}` : `${process.env.REACT_APP_BASE_URL}projects/AddProject`
    try {
      const response = isEdit ? await axios.put(
        url ,{
             name:user.name,
             skills:!trueValue?editseleted:user.skills,
             startDate:user.startDate,
             endDate:user.endDate,
             resources:user.resources,
             companyName:user.companyName,
             timeDuration:user.timeDuration
         }
       ) : await axios.post(
       url ,{
            name:user.name,
            skills:user.skills,
            startDate:user.startDate,
            endDate:user.endDate,
            resources:user.resources,
            companyName:user.companyName,
            timeDuration:user.timeDuration
            
        }
      );
     
      if(response.status == 200){
        if(isEdit){
            setUser('')  
        }
        toast.success(`Project ${isEdit ? 'Updated':'Added'} Successfully`)
        setModal(false);
        setLoader(true)

      }
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data.message)
    }
  };
  

  useEffect(() => {
    async function fetchItems() {
      try {
        const data = await getSkillData(); // Fetch data from /api/items
        setUserSkill(data?.data);
;
      } catch (error) {
        console.error("Failed to fetch items:", error);
      }
    }
    fetchItems();
  }, []);

  useEffect(()=>{
    const editSkillIds = editData?.skills?.map(item => item.id);
    setEditSeleted(editSkillIds)
  },[editData])
  
  const selecteskill = userSkill.map((item) => {
    return {
      label: item.name, // Mapping name to label
      value: item.id, // Mapping id to value
    };
  });
  
  const editskill = editData?.skills?.map(item=>{
    return {
      label:item.name,
      value:item.id
    }
  })


  return (
    <div>
      <h3 class="text-xl mb-4 font-bold text-left pb-2 border-b-2 border-gray">{editData ? 'Edit Project':'Add New Project'}</h3>
      <div>
        <label
          for="first_name"
          class="block mb-2 text-left text-sm font-medium"
        >
          {" "}
          Name
        </label>
        <input
          name="name"
          value={user.name}
          onChange={handelInput}
          type="text"
          id="first_name"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray "
          placeholder="Project Name"
          required
        />
        {errors.name && <p className="text-left text-red">{errors.name}</p>}
        <div className="pt-3 text-left">
          <label
            for="first_name"
            class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white"
          >
            Skill
          </label>
          <Select
            isMulti
            name="skill"
            options={selecteskill}
            onChange={handleSelectChange}
            className="basic-multi-select rounded-sm"
            classNamePrefix="select skills"
            defaultValue={editskill}
          />
        </div>
        {errors.skill && <p className="text-left text-red">{errors.skill}</p>}
       
        <div>
          <label
            for="start-date"
            class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white pt-2"
          >
            Start Date
          </label>
          <input
            type="date"
            value={user.startDate}
            onChange={handelInput}
            name="startDate"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray "
          />
        </div>
        <div>
          <label
            for="end-date"
            class="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-white pt-2"
          >
            End Date
          </label>
          <input
            type="date"
            disabled={!disbleDate}
            min={user.startDate}
            value={user.endDate}
            onChange={handelInput}
            name="endDate"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray "
          />
        </div>
        <label
          for="first_name"
          class="block mb-2 text-left text-sm font-medium"
        >
          Time Duration
        </label>
        <input
          name="timeDuration"
          value={user.timeDuration}
          onChange={handelInput}
          type="number"
          id="first_name"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray "
          placeholder="Resource" 
          required
          min="1"
        />
        <label
          for="first_name"
          class="block mb-2 text-left text-sm font-medium"
        >
          Resource 
        </label>
        <input
          name="resources"
          value={user.resources}
          onChange={handelInput}
          type="number"
          id="first_name"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray "
          placeholder="Resource" 
          required
          min="1"
        />
        {errors.resources && <p className="text-left text-red">{errors.resources}</p>}
        <label
          for="first_name"
          class="block mb-2 text-left text-sm font-medium"
        >
          Company Name
        </label>
        <input
          name="companyName"
          value={user.companyName}
          onChange={handelInput}
          type="text"
          id="first_name"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray "
          placeholder="Company Name"
          required
        />
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse mb-6">
          <span className="mt-8 flex w-full justify-center gap-4 rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button
              type="button"
              onClick={(e) => handelSubmit(e)}
              className="inline-flex justify-center w-1/3 rounded-md border border-gray-300 px-4 py-2   hover:bg-lightblue hover:text-black text-white bg-blue leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
             {editData? 'Update':'Submit'} 
            </button>
            <button
              type="button"
              onClick={() => setModal(false)}
              className="inline-flex justify-center w-1/3 rounded-md border border-gray-300 px-4 py-2  text-white bg-lightred leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              Close
            </button>
          </span>
        </div>
    </div>
  );
}

export default AddProject;
